enum PAGE_ROUTES {
	HOME = "/",
	FORGET_PASSWORD = "/forget-password",
	NEW_PASSWORD = "/new-password/:token",
	RESEND_PASSWORD = "/resend-password",
	CONFIRM_EMAIL = "/confirm-email/:token",
	SIGN_IN = "/sign-in",
	ACCESS_DENIED = "/access-denied",
	ORDERS = "/orders",
	PRODUCTS = "/products",
	CUSTOMERS = "/customers",
	CUSTOMERS_CUSTOMERS = "/customers/customers",
	REQUESTS = "/customers/requests",
	BILLING_ADDRESS = "/customers/customers/:customerId/billing-address/:billingId",
	EDIT_CUSTOMER = "/customers/customers/edit/:id",
	ADD_CUSTOMERS_TASK = "/customers/customers/:customerId/task/add",
	EDIT_CUSTOMERS_TASK = "/customers/customers/:customerId/task/edit/:taskId",
	ORDER = "/orders/:orderId/:jobId",
	ORDER_ADD_INVOICE = "/orders/:orderId/:jobId/invoice/add",
	ORDER_EDIT_INVOICE = "/orders/:orderId/:jobId/edit/invoice/:invoiceId",
	ORDER_INVOICE_VIEW = "/orders/:orderId/:jobId/view/invoice/:invoiceId",
	OPEN_ORDERS = "/orders/open-orders",
	OPEN_ORDERS_ADD_TASK = "/orders/:orderId/:jobId/task/add",
	OPEN_ORDERS_EDIT_TASK = "/orders/:orderId/:jobId/edit/task/:taskId",
	COMPLETED_ORDERS = "/orders/completed-orders",
	CANCELLED_ORDERS = "/orders/cancelled-orders",
	OPEN_ORDERS_INVOICES = "/orders/open-orders-invoices",
	COMPLETED_ORDER_INVOICES = "/orders/completed-order-invoices",
	OPEN_ESTIMATES = "/orders/open-estimates",
	VIEW_OPEN_ESTIMATE = "/orders/open-estimates/edit/:id",
	COMPLETE_ESTIMATES = "/orders/complete-estimates",
	VIEW_COMPLETE_ESTIMATE = "/orders/complete-estimates/edit/:id",
	REACTIVATED_ESTIMATES = "/orders/reactivated-estimates",
	VIEW_REACTIVATED_ESTIMATE = "/orders/reactivated-estimates/edit/:id",
	VIEW_EMAIL_LOG = "/orders/:custom/edit/:id/view-email-log",
	EMAIL_TEMPLATES = "/orders/email-templates",
	ADD_EMAIL_TEMPLATE = "orders/email-templates/add",
	EDIT_EMAIL_TEMPLATE = "orders/email-templates/edit/:id",
	PROCUREMENT_REPORT = "/orders/procurement-report",
	SEND_A_FILE = "/orders/send-a-file",
	PRODUCTS_PRODUCTS = "/products/products",
	ADD_PRODUCTS = "/products/products/add",
	EDIT_PRODUCTS = "/products/products/edit/:id",
	PRODUCT_CATEGORIES = "/products/product-categories",
	ADD_PRODUCT_CATEGORIES = "/products/product-categories/add",
	EDIT_PRODUCT_CATEGORIES = "/products/product-categories/edit/:id",
	MENU_CATEGORIES = "/products/menu-categories",
	ADD_MENU_CATEGORY = "/products/menu-categories/add",
	EDIT_MENU_CATEGORY = "/products/menu-categories/edit/:id",
	ATTRIBUTE_CATEGORIES = "/products/attribute-categories",
	ADD_ATTRIBUTE_CATEGORIES = "/products/attribute-categories/add",
	EDIT_ATTRIBUTE_CATEGORIES = "/products/attribute-categories/edit/:id",
	ATTRIBUTES = "/products/attributes",
	ADD_ATTRIBUTE = "/products/attributes/add",
	EDIT_ATTRIBUTE = "/products/attributes/edit/:id",
	MAILING_SERVICE_FEES = "/products/mailing-service-fees",
	IMPORT_PRODUCT_PRICING = "/products/import-product-pricing",
	TEMPLATES = "/products/templates",
	ADD_TEMPLATE = "/products/templates/add",
	EDIT_TEMPLATE = "/products/templates/edit/:id",
	TEMPLATE_CATEGORIES = "/products/template-categories",
	ADD_TEMPLATE_CATEGORY = "/products/template-categories/add",
	EDIT_TEMPLATE_CATEGORY = "/products/template-categories/edit/:id",
	PROCUREMENT_CUSTOMERS = "/customers/procurement-customers",
	CONTACT_FORM_MESSAGE = "/customers/contact-form-message",
	ADMINISTRATION = "/administration",
	TASKS = "/administration/tasks",
	ADD_TASK = "/administration/tasks/add",
	EDIT_TASK = "/administration/tasks/edit/:taskId",
	DEVELOPERS_TASKS = "/administration/development-tasks",
	ADD_DEVELOPER_TASKS = "/administration/development-tasks/add",
	EDIT_DEVELOPER_TASKS = "/administration/development-tasks/edit/:taskId",
	USERS = "/administration/users",
	ADD_USER = "/administration/users/add",
	EDIT_USER = "/administration/users/edit/:id",
	PROMO_CODES = "/administration/promo-codes",
	ADD_PROMO_CODE = "/administration/promo-codes/add",
	EDIT_PROM_CODE = "/administration/promo-codes/edit/:id",
	SHIPPING_ZIPS = "/administration/shipping-zips",
	ADD_SHIPPING_ZIP = "/administration/shipping-zips/add",
	EDIT_SHIPPING_ZIP = "/administration/shipping-zips/edit/:id",
	SHIPPING_RATE_MARKUP = "/administration/shipping-rate-markup",
	BANNED_IPS = "/administration/banned-ips",
	ADMIN_LOG_IN = "/administration/log-in",
	CMS = "/cms",
	HOMEPAGE = "/cms/homepage",
	ADD_BANNER = "/cms/homepage/add-banner",
	EDIT_BANNER = "/cms/homepage/edit/:id",
	BEST_SELLER = "/cms/best-seller",
	ADD_BEST_SELLER = "/cms/best-seller/add",
	EDIT_BEST_SELLER = "/cms/best-seller/edit/:id",
	GIVE_BACK = "/cms/give-back",
	ADD_GIVE_BACK = "/cms/give-back/add",
	EDIT_GIVE_BACK = "/cms/give-back/edit/:id",
	VENDORS = "/cms/vendors",
	ADD_VENDORS = "/cms/vendors/add",
	EDIT_VENDORS = "/cms/vendors/edit/:id",
}
export default PAGE_ROUTES;
