import { useState } from "react";

import { ISet } from "@features/orders/order/types";
import download from "@assets/images/download.svg";
import { useAppDispatch } from "@features/app/hooks";
import { getArtworkZipDownloadLink } from "@features/orders/order/actions";
import { CircularProgress } from "@mui/material";
import Select from "@containers/common/Select";
import { getOrderStatusesOptions } from "@utils/orderStatuses";

import { Artwork, Proof, ProofCustomer } from "./components";
import {
  SetsContainer,
  BoxInformation,
  Title,
  StyledBox,
  SetNameWrapper,
  DownloadLink,
  SetAndStatusWrapper,
  StatusWrapper,
} from "./styled";

interface SetsProps {
  setsList: ISet[];
  jobIndex: number;
  shipmentIndex: number;
}

const Sets = ({ setsList, jobIndex, shipmentIndex }: SetsProps) => {
  if (!setsList?.length) {
    return null;
  }

  const statusOptions = getOrderStatusesOptions();

  const dispatch = useAppDispatch();

  return setsList.map((set, setIndex) => {
    const setStatusKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}].status`;

    const {
      id,
      shipmentId,
      backArtWork,
      frontArtWork,
      created,
      proofCustomerLogs,
      ...setRest
    } = set;

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownloadClick = async (setId: string) => {
      setIsDownloading(true);

      const response = await dispatch(
        getArtworkZipDownloadLink({ setId }),
      ).unwrap();

      setIsDownloading(false);

      const link = document.createElement("a");

      link.href = response;
      link.download = "artwork.zip";
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    };

    return (
      <SetsContainer key={`${shipmentId} ${id}`}>
        <SetNameWrapper>
          <SetAndStatusWrapper>
            <div>
              <p>{setRest.name}</p>
              <span>{set.setNumber}</span>
            </div>
            <StatusWrapper>
              <span>Job status:</span>
              <Select
                label=""
                name={setStatusKey}
                placeholderText="Search by Priority"
                options={statusOptions}
              />
            </StatusWrapper>
          </SetAndStatusWrapper>
        </SetNameWrapper>
        <BoxInformation>
          <StyledBox>
            <Title>
              ARTWORK
              <DownloadLink onClick={() => handleDownloadClick(id)}>
                {" "}
                {isDownloading && <CircularProgress size={15} />}
                <img src={download} alt={download} />
                Download available art (ZIP)
              </DownloadLink>
            </Title>
            <Artwork
              created={created}
              jobIndex={jobIndex}
              setIndex={setIndex}
              shipmentIndex={shipmentIndex}
            />
          </StyledBox>
          <StyledBox>
            <Proof
              setId={id}
              jobIndex={jobIndex}
              setIndex={setIndex}
              shipmentIndex={shipmentIndex}
              backProofStatus={setRest.backProofStatus}
              frontProofStatus={setRest.frontProofStatus}
            />
          </StyledBox>
          <StyledBox>
            <Title>PROOF UPLOAD & STATUS LOG</Title>
            <ProofCustomer proofCustomerLogs={proofCustomerLogs} />
          </StyledBox>
        </BoxInformation>
      </SetsContainer>
    );
  });
};

export default Sets;
