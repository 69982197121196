import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectCategories } from '@features/categories/selectors';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { addVendorThunk, editVendorThunk } from '@features/vendors/actions';
import PAGE_ROUTES from '@routes/routingEnum';
import { VendorCreationPayload } from '@features/vendors/types';
import { usStateOptions } from '@containers/Customers/Customers/BillingInfo/InputsTable/optionsList';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { AddDataSchema, IAddDataForm, inputsRows, defaultValues, preparePayloadData } from './helpers';

interface IInputsTable{
  vendorsData?: VendorCreationPayload;
}

const InputsTable = ({ vendorsData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectCategories);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema),
    defaultValues: vendorsData ?? defaultValues,
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = (data: IAddDataForm) => {
    const preparedData = preparePayloadData(data);

    dispatch(
      vendorsData
        ? editVendorThunk(preparedData)
        : addVendorThunk(preparedData),
    )
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!vendorsData) {
          navigate(`${PAGE_ROUTES.VENDORS}?created='done'`);

          return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e && e?.statusCode === 409 && e?.message === "Vendor with the provided name already exists!") {
          setError('name', { message: e?.message }, { shouldFocus: true });
        }

        // eslint-disable-next-line no-console
        console.log("Request Error: ", e);
      });
  };

  return (
    <TitlesWithBackButton title={vendorsData ? 'Edit' : 'Add new'} path="VENDORS">
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable
            tableTitle="Vendor details"
            colSpan={2}
            sx={{ maxWidth: 660 }}
          >
            {inputsRows.map((item) => {
              return (
                <RowComponent key={item.label} {...item}>
                  <ReusableFields
                    {...item}
                    selectList={[
                      {
                        field: 'state',
                        options: usStateOptions,
                      },
                      {
                        field: 'carrierUsed',
                        options: [
                          {
                            optionName: "UPS",
                            value: 'UPS',
                          },
                          {
                            optionName: "FedEx",
                            value: 'FedEx',
                          },
                        ],
                      },
                    ]}
                  />
                </RowComponent>
              );
            })}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />

        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
