import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { IAddAttributePayload } from '@features/attributes/types';
import { selectAttributes } from '@features/attributes/selectors';
import { addAttribute, editAttribute } from '@features/attributes/actions';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import { getOptionsArray } from '@utils/helpers';
import Input from '@containers/common/Input';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { resetAttributes } from '@features/attributes/slice';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

import { AddDataSchema, IAddDataForm, inputsRows, defaultValues } from './helpers';

interface IInputsTable{
  attributesData?: IAddAttributePayload;
}

const InputsTable = ({ attributesData }: IInputsTable) => {
  const [fields, setFields] = useState<ValidFieldNames[]>(inputsRows);

  const { data: attributeCategories } = useAppSelector(selectAttributeCategories);

  const categoriesList = getOptionsArray(attributeCategories, 'name');
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectAttributes);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema) as any, // TODO: change any
    defaultValues: attributesData ?? defaultValues,
  });

  const { watch, handleSubmit, setError, register, formState: { errors } } = methods;

  const selectedCategory = watch('attributeCategory');

  const onSubmit = (data: IAddDataForm) => {
    const payload = { ...data };

    if (data.defaultPrice) {
      payload.defaultPrice = +data.defaultPrice;
    }

    dispatch(attributesData ? editAttribute(payload) : addAttribute(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!attributesData) {
          dispatch(resetAttributes());
          navigate(PAGE_ROUTES.ATTRIBUTES);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Attribute with the provided name already exists in this attribute category!') {
          setError('name', { message: e.message });
        } else if (e.message === 'Attribute with the provided nickname already exists in this attribute category!') {
          setError('nickname', { message: e.message });
        } else {
          navigate(PAGE_ROUTES.ATTRIBUTES);
        }
      });
  };

  useEffect(() => {
    const found = attributeCategories?.find((item) => item.id === selectedCategory);

    if (found && found.folding) {
      const newField = {
        label: 'Attribute Image (shown to customer when attribute is selected)',
        field: 'image',
        type: InputTypes.image,
        isRequired: true,
      };

      setFields((p) => [...p.slice(0, 3), newField, ...p.slice(3)]);

      return;
    }

    setFields(inputsRows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <TitlesWithBackButton
      title={attributesData ? 'Edit Attribute' : 'Add Attribute'}
      path="ATTRIBUTES"
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="ATTRIBUTE" colSpan={2}>
            {fields.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[{
                    field: 'attributeCategory',
                    options: categoriesList,
                  }]}
                />
              </RowComponent>
            ))}
            <RowComponent label="Default Price">
              <Stack direction="row" alignItems="center" gap="8px">
                <Typography>$</Typography>
                <Input
                  width="80px"
                  type="text"
                  placeholder="00.00"
                  {...register('defaultPrice')}
                  errorMessage={errors?.defaultPrice?.message as string}
                />
              </Stack>
            </RowComponent>
            <RowComponent label="4over Code">
              <Input
                type="text"
                placeholder="4over Code"
                {...register('fouroverCode')}
                errorMessage={errors?.fouroverCode?.message as string}
              />
            </RowComponent>
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />

        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
