import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import { addProductsAttributes } from '@features/products/productsAttributes/actions';
import { useParams } from 'react-router-dom';
import { selectProductsAttributes } from '@features/products/productsAttributes/selectors';
import { GrandFormatOptions } from '@features/products/basicInfo/types';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';
import { Box } from '@mui/material';

import { AddDataSchema, IAddDataForm, addingIsSelectedFlag, formattingPayload } from './helpers';
import Summary from '../Summary';
import CheckboxList from '../CheckboxList';
import { SectionWrapper, LocalStyledStack } from './styles';

interface NewAddAttributesProps {
  grandFormatOptions?: GrandFormatOptions | null;
}

const InputsTable = ({ grandFormatOptions }:NewAddAttributesProps) => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();
  const { data: productsAttributes } = useAppSelector(selectProductsAttributes);
  const { productAttributeCategories } = useAppSelector(selectAttributeCategories);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const initialState = addingIsSelectedFlag(productAttributeCategories, productsAttributes);

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: {
      attributesList: initialState,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: IAddDataForm) => {
    setIsSuccessMsg({ msg: '', status: null });

    const body = formattingPayload(data);

    dispatch(addProductsAttributes({ body, id: productId as string }))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
      })
      .catch((error: any) => {
        if (typeof error.message === 'string') {
          setIsSuccessMsg({ msg: error.message, status: 400 });
        } else {
          setIsSuccessMsg({ msg: GLOBAL_MESSAGES.wrong, status: 400 });
        }
      });
  };

  return (
    <SectionWrapper>
      <FormProvider {...methods}>
        <LocalStyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <div>
            <CheckboxList type="product" grandFormatOptions={grandFormatOptions} />
            <Box
              sx={{
                margin: '0px -300px 0px 212px',
              }}
            >
              <GlobalUpdatesMessage {...isSuccessMsg} />
            </Box>
          </div>
          <Summary type="product" />
        </LocalStyledStack>
      </FormProvider>
    </SectionWrapper>
  );
};

export default memo(InputsTable);
