import { IProductsSetPrice } from "@features/products/setPrice/types";
import { numberSchema } from "@utils/schemas";
import * as yup from "yup";

export interface IAddDataForm {
  quantities: IProductsSetPrice[];
  seperatedCategories: string[];
}

export const AddDataSchema = yup.object().shape({
  quantities: yup.array().of(
    yup.object({
      originalPrice: numberSchema
        .required("Size is required")
        .max(999999999, "The maximum number of digits is 9"),
      size: numberSchema
        .required("Size is required")
        .max(999999999, "The maximum number of digits is 9"),
      basePrice: numberSchema
        .required("Base Price is required")
        .max(999999999, "The maximum number of digits is 9"),
      attributeCategories: yup.array().of(
        yup.object({
          attributes: yup.array().of(
            yup.object({
              price: numberSchema
                .required("Price is required")
                .max(999999999, "The maximum number of digits is 9"),
            }),
          ),
        }),
      ),
    }),
  ),
});
