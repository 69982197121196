import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows1: ValidFieldNames[] = [
  {
    label: 'Use Templates',
    field: 'templateId',
    type: InputTypes.select,
    isRequired: false,
  },
  {
    label: 'Menu Category',
    field: 'categoryId',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Product Category:',
    field: 'subCategoryId',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Print Type',
    field: 'productTypeId',
    type: InputTypes.select,
    isRequired: false,
  },
  {
    label: 'Product SKU',
    field: 'productSKU',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Size',
    field: 'name',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Product Weight (1)',
    field: 'weight',
    type: InputTypes.text,
    placeholder: 'Product Weight',
    inputprops: {
      width: '120px',
    },
  },
];

export const inputsRows2: ValidFieldNames[] = [
  {
    label: 'Visible on Site',
    field: 'visibleOnSite',
    type: InputTypes.checkbox,
  },
  // {
  //   label: 'Can Be Discounted with Promo Codes',
  //   field: 'isDiscountable',
  //   type: InputTypes.checkbox,
  // },
  // {
  //   label: 'Quarterhouse Product Code',
  //   field: 'quarterhouseProductCode',
  //   type: InputTypes.text,
  // },
  {
    label: '4over Prod Code',
    field: 'fouroverProdCode',
    type: InputTypes.text,
  },
];
