import { memo, useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '@containers/common/Input';
import PAGE_ROUTES from '@routes/routingEnum';
import Select from '@containers/common/Select';
import { paginationLimit } from '@utils/constants';
import { constructQueryString } from '@utils/helpers';
import DatePicker from '@containers/common/DatePicker';
import { getOrderStatusesOptions } from '@utils/orderStatuses';
import StyledSearchSection from '@containers/common/StyledSearchContainer';
import { StyledDatePicker, StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import { useDispatch } from 'react-redux';
import { selectRowsArray } from '@features/orders/sets/slice';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import SearchBtnForOrdersPage from './SearchBtnForOrdersPage';
import { FiltersSchema, IFiltersForm } from './helpers';
import { OrderKindsContext } from '../..';
import { KindType, getOrderLinkByKind } from '../../helper';

const SearchSection = () => {
  const navigate = useNavigate();
  const jobsKind = useContext(OrderKindsContext);
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const params = queryString.parse(search);
  const statusOptions = getOrderStatusesOptions(true);
  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema as any),
    defaultValues: { status: '', ...params },
  });

  const { reset, handleSubmit, register, formState: { errors } } = methods;

  const onSubmit = (data: IFiltersForm) => {
    dispatch(selectRowsArray([]));

    const { status } = data;

    const queryParams = constructQueryString({
      ...data,
      offset: '0',
      limit: paginationLimit,
      status,
      dateFrom: data.dateFrom ? new Date(data.dateFrom).toISOString() : '',
      dateTo: data.dateTo ? new Date(data.dateTo).toISOString() : '',
    });

    if (jobsKind) {
      const newPath = getOrderLinkByKind(jobsKind as KindType);

      navigate(`${newPath}?${queryParams}`);

      return;
    }

    navigate(`${PAGE_ROUTES.OPEN_ORDERS}?${queryParams}`);
  };

  useEffect(() => {
    if (pathname) {
      reset();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Search"
              errorMessage={errors?.searchTerm?.message}
            />
            <StyledDatePicker>
              <DatePicker
                name="dateFrom"
                dateLabel=""
                inputProps={{ placeholder: 'Date From:', width: '100px' }}
                errorMessage={errors?.dateFrom?.message as string}
              />
            </StyledDatePicker>
            <StyledDatePicker>
              <DatePicker
                name="dateTo"
                dateLabel=""
                inputProps={{ placeholder: 'Date To', width: '153px' }}
                errorMessage={errors?.dateTo?.message as string}
              />
            </StyledDatePicker>

            {
              // if jobsKind is undefined it is means 'Open tickets' page is
              !jobsKind && (
              <Select
                label=""
                name="status"
                placeholderText="Search by status"
                options={statusOptions}
                errorMessage={errors.status?.message as string}
              />
              )
            }

            <SearchBtnForOrdersPage reset={reset} />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
