import { Dispatch, SetStateAction, memo, useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';
import Input from '@containers/common/Input';
import {
  deleteMailingServiceFee, editMailingServiceFee, getAllMailingServiceFees,
} from '@features/mailingServiceFees/actions';
import { useFormContext } from 'react-hook-form';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import StyledTypography from '@containers/common/StyledTypography';
import Button from '@containers/common/Button';
import Stack from '@mui/material/Stack';
import { selectMailingServiceFees } from '@features/mailingServiceFees/selectors';
import { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { fieldNames } from '../helpers';
import { formattedPayload } from './helpers';

interface ITableRow {
  idx: number;
  id: string;
  setIsSuccessMsg?: Dispatch<SetStateAction<GlobalUpdatesMessageProps>>;
}

const TableRow = ({ id, idx, setIsSuccessMsg }: ITableRow) => {
  const dispatch = useAppDispatch();
  const { data: mailingServiceFees } = useAppSelector(selectMailingServiceFees);

  const [isEdit, setIsEdit] = useState(false);

  const { register, formState: { errors }, handleSubmit, setValue, setError, clearErrors } = useFormContext();

  const deleteAction = useCallback(() => {
    dispatch(deleteMailingServiceFee(id)).unwrap().catch(() => {
      // TODO: define logic
      getAllMailingServiceFees();
    }).finally(() => dispatch(getAllMailingServiceFees()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setIsEdit(false);
    setValue('mailingServiceData', mailingServiceFees);
    clearErrors();
  };

  const handleEditClick = () => {
    setIsEdit((prev) => !prev);
  };

  const onSubmit = (data: any) => {
    const payload = formattedPayload(data, id);

    if (payload) {
      dispatch(editMailingServiceFee(payload))
        .unwrap()
        .then(() => {
          setIsSuccessMsg && setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
          dispatch(getAllMailingServiceFees());
        })
        .catch((e) => {
          if (e.message === 'Mailing fee with the provided quantity already exists!') {
            setError(`mailingServiceData.${idx}.quantity`, { message: e.message });
          }
        });
    }
  };

  return (
    <StyledTableRow>
      {fieldNames.map(({ label, field }) => (
        <TableCell key={label}>
          <Input
            disabled={!isEdit}
            placeholder={label}
            width="120px"
            {...register(`mailingServiceData.${idx}.${field}` as any)}
            errorMessage={(errors as any)?.mailingServiceData?.[idx]?.[field]?.message}
          />
        </TableCell>
      ))}
      {isEdit ? (
        <TableCell colSpan={2}>
          <Stack direction="row" gap="12px">
            <Button onClick={handleCancel} isOutlined>Cancel</Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>Save</Button>
          </Stack>
        </TableCell>
      ) : (
        <>
          <DeleteBtn
            deleteAction={deleteAction}
            questionText="Are you sure you want to delete this mailing service fee ?"
          />
          <TableCell>
            <StyledTypography color="blue" variant="body3" onClick={handleEditClick}>Edit</StyledTypography>
          </TableCell>
        </>
      )}
    </StyledTableRow>
  );
};

export default memo(TableRow);
