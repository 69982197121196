import { Dispatch, SetStateAction, memo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import { StyledStack, StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { selectSubcategories } from '@features/subcategories/selectors';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import Input from '@containers/common/Input';
import { addProductsQuantity } from '@features/products/productsQuantity/actions';
import Typography from '@mui/material/Typography';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { AddDataSchema, IAddDataForm, defaultValues, formattingPayload } from './helpers';
import { headCells } from './tableData';

interface IAddComponent {
  setIsQuantityAdded: Dispatch<SetStateAction<boolean>>;
  isQuantityAdded: boolean;
}

const AddComponent = ({ setIsQuantityAdded, isQuantityAdded }:IAddComponent) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { actionLoading } = useAppSelector(selectSubcategories);

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any), // TODO: add typing
    defaultValues: { ...defaultValues, productId: id },
  });

  const { handleSubmit, register, setError, setValue, formState: { errors } } = methods;

  const onSubmit = async (data: IAddDataForm) => {
    if (!id) {
      setError('quantity', { message: GLOBAL_MESSAGES.wrong });

      return;
    }

    try {
      const body = formattingPayload(data);

      await dispatch(addProductsQuantity({ id, body })).unwrap();

      setValue('quantity', null);
      setIsQuantityAdded(!isQuantityAdded);
    } catch (e: any) {
      if (typeof e?.message === 'string') {
        setError('quantity', { message: e?.message });
      } else {
        setError('quantity', { message: GLOBAL_MESSAGES.wrong });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledStack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        mb="32px"
      >
        <Typography variant="h9" mb="16px">Add new</Typography>
        <StyledTable headCells={headCells}>
          <StyledTableRow sx={{ verticalAlign: 'top' }}>
            <StyledTableCell>
              <Input
                placeholder="Quantity"
                {...register('quantity')}
                errorMessage={errors?.quantity?.message}
              />
            </StyledTableCell>
          </StyledTableRow>
        </StyledTable>
        <SubmitBtn actionLoading={actionLoading} />
      </StyledStack>
    </FormProvider>
  );
};

export default memo(AddComponent);
