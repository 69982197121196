import { memo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import PAGE_ROUTES from '@routes/routingEnum';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { resetShippingZips } from '@features/shippingZip/slice';
import { addShippingZip, editShippingZip } from '@features/shippingZip/actions';
import { selectShippingZips } from '@features/shippingZip/selectors';
import RowComponent from '@containers/common/Table/components/RowComponent';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { inputsRows, defaultValues, IAddDataForm, AddDataSchema } from './helpers';
import { IInputsTable } from './types';

const InputsTable = ({ editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { actionLoading } = useAppSelector(selectShippingZips);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData ?? defaultValues,
  });

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const { handleSubmit, setError } = methods;

  const onSubmit = (data: IAddDataForm) => {
    const newData = {
      name: data?.name,
      zipCode: `${data.zipCode}`,
    };

    dispatch(
      editData
        ? editShippingZip({ ...newData, id })
        : addShippingZip(newData),
    ).unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!editData) {
          dispatch(resetShippingZips());
          navigate(PAGE_ROUTES.SHIPPING_ZIPS);
        }
      }).catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'Shipping zip name already exists!') {
          setError('name', { message: e.message });
        } else if (e.message === 'Shipping zip code already exists!') {
          setError('zipCode', { message: e.message });
        } else {
          setIsSuccessMsg({ msg: 'Something went wrong', status: 400 });
        }
      });
  };

  return (
    <TitlesWithBackButton title={editData ? 'Edit Shipping Zip' : 'Add Shipping Zip'} path="SHIPPING_ZIPS">
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="SHIPPING FROM ZIP LOCATION" colSpan={2}>
            {inputsRows.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields {...item} />
              </RowComponent>
            ))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
