import { useFormContext } from "react-hook-form";
import { FormControlLabel, Typography } from "@mui/material";

import { IIsSelectedShippingZip } from "../../../../types";
import { Container, StyledLabel, CheckboxGroup, StyledCheckbox } from "./styles";

const CustomShippingFacility = ({ idx }: {idx: number}) => {
  const { watch, setValue } = useFormContext();
  const { customShippingFacility, shippingZips } = watch(`productTypes[${idx}]`);

  const handleSingleCheckboxChange = (id: string) => {
    const newShippingZipsIds = shippingZips.map((item: IIsSelectedShippingZip) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      }

      return item;
    });

    setValue(`productTypes[${idx}].shippingZips`, newShippingZipsIds);
  };

  return (
    <Container>
      <StyledLabel>
        <StyledCheckbox
          disableRipple
          checked={!!customShippingFacility}
          onChange={() => {
            setValue(`productTypes[${idx}].customShippingFacility`, !customShippingFacility);
          }}
        />
        <Typography>Custom shipping facility </Typography>
      </StyledLabel>
      {
        customShippingFacility && (
          <CheckboxGroup>
            {shippingZips?.map(({ id, name }: IIsSelectedShippingZip, idxx: number) => {
              return (
                <FormControlLabel
                  key={id}
                  control={(
                    <StyledCheckbox
                      disableRipple
                      checked={shippingZips[idxx].isSelected}
                      onChange={() => {
                        handleSingleCheckboxChange(id);
                      }}
                    />
                  )}
                  label={<Typography variant="body3">{name}</Typography>}
                />
              );
            })}
          </CheckboxGroup>
        )
      }
    </Container>
  );
};

export default CustomShippingFacility;
