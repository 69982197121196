import { memo } from 'react';

import Button from '@containers/common/Button';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { getSetSelectedRow } from '@features/orders/sets/selectors';
import StyledSearchSection from '@containers/common/StyledSearchContainer';
import { getOrderStatusesOptions } from '@utils/orderStatuses';
import { FormProvider, useForm } from 'react-hook-form';
import Select from '@containers/common/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSetsThunk, updateSetsStatuses } from '@features/orders/sets/actions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { GlobalQueryString } from '@utils/globalTypes';
import PAGE_ROUTES from '@routes/routingEnum';
import { OrderStatusesEnum } from '@features/orders/order/enums';
import { selectRowsArray } from '@features/orders/sets/slice';
import { ordersPaginationLimit } from '@utils/constants';
import { getEstimatesCount } from '@features/sidebarCounts/actions';

import { UpdateSetsStatusSchema, UpdateStatusForm } from './helper';
import {
  StyledStack,
  StyledTypography,
} from './styled';

const ActionsWithJobs = () => {
  const dispatch = useAppDispatch();
  const statusOptions = getOrderStatusesOptions();
  const openJobsSelectedRows = useAppSelector(getSetSelectedRow);
  const { search, pathname } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;

  const methods = useForm<UpdateStatusForm>({
    resolver: yupResolver(UpdateSetsStatusSchema),
    defaultValues: { status: '' },
  });

  const { handleSubmit, formState: { errors }, reset } = methods;

  const onSubmit = async ({ status }: UpdateStatusForm) => {
    if (!status) {
      return null;
    }

    let fixedStatus = {};

    if (PAGE_ROUTES.COMPLETED_ORDERS === pathname) {
      fixedStatus = {
        status: OrderStatusesEnum.Complete,
      };
    } else if (PAGE_ROUTES.CANCELLED_ORDERS === pathname) {
      fixedStatus = {
        status: OrderStatusesEnum.Canceled,
      };
    }

    try {
      await dispatch(updateSetsStatuses({ status, ids: openJobsSelectedRows }));

      await dispatch(getSetsThunk({
        ...params,
        offset: 0,
        limit: ordersPaginationLimit,
        ...fixedStatus,
      })).unwrap();

      dispatch(selectRowsArray([]));
      await dispatch(getEstimatesCount());

      reset();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, '-----error');
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          marginBottom: '16px',
        }}
      >
        {openJobsSelectedRows.length}
        {' '}
        Items Selected
      </Typography>

      <StyledSearchSection>
        <StyledTypography
          variant="body1"
        >
          Change status of all checked orders to
        </StyledTypography>
        <FormProvider {...methods}>
          <StyledStack
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            gap="24px"
          >
            <Select
              name="status"
              options={statusOptions}
              errorMessage={errors?.status?.message as string}
            />
            <Button type="submit"> Update Status </Button>
          </StyledStack>
        </FormProvider>
      </StyledSearchSection>
    </>
  );
};

export default memo(ActionsWithJobs);
