import { useMemo } from "react";

import { OrderStatusesEnum } from "@features/orders/order/enums";

export const getOrderStatusesOptions = (exception?: boolean) => {
  // 'exception' variable excepts Complete and Canceled statuses
  const statusOptions = useMemo(() => {
    const colorArrayManual: { optionName: string; value: string }[] = [];

    for (const key in OrderStatusesEnum) {
      if (OrderStatusesEnum.hasOwnProperty(key)) {
        if (
          exception && [OrderStatusesEnum.Canceled, OrderStatusesEnum.Complete].includes(key as OrderStatusesEnum)
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }

        colorArrayManual.push({
          optionName: key.replace(/([A-Z])/g, ' $1').trim(),
          value: OrderStatusesEnum[key as keyof typeof OrderStatusesEnum],
        });
      }
    }

    colorArrayManual.sort((a, b) => a.optionName.localeCompare(b.optionName));

    return colorArrayManual;
  }, [exception]);

  return statusOptions;
};
