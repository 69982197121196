import moment from 'moment';
import Input from '@containers/common/Input';
import { Checkbox, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { ISet, StatusOptions } from '@features/orders/sets/types';
import { selectRow } from '@features/orders/sets/slice';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectSets } from '@features/orders/sets/selectors';
import { UpdateShipmentPayload } from '@features/orders/order/types';
import { priceFormatting, SHORT_DATE_FORMAT } from '@utils/helpers';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum } from '@features/orders/order/enums';
import ErrorMessage from '@containers/common/ErrorMessage';
import { PrevState } from '@utils/prevState';
import { requestToArtwork } from '@utils/imageService';
import { updateSetsStatuses } from '@features/orders/sets/actions';
import { getEstimatesCount } from '@features/sidebarCounts/actions';

import { IVendorOptions, ValidMessageState } from '../useSetsListLogic';
import OrderShipments from '../OrderShipments';
import {
  StyledSelect,
  OrderIdLink,
  StyledOrderTableRow,
  StyledTCell,
} from '../styled';
import { findIsEditable, getPriority } from './helper';

interface OrderItemInListProps extends ISet {
  statusOptions: StatusOptions[];
  vendorsList: IVendorOptions[];
  validMessageState: ValidMessageState;
  beginningDebounceForEachChange: (key: OrderListUpdateRequestFieldKeys, value: string) => void;
  beginningDebounceForShipmentChanges: (args: UpdateShipmentPayload) => void;
}

const SetItemInList = ({
  orderId,
  // jobId,
  shipmentId,
  setId,
  setNumber,
  frontArtWork,
  backArtWork,
  status,
  date,
  billTo,
  vendorJob,
  vendorId,
  // vendorName,
  cost,
  mailPo,
  hasOpenTasks,
  isEDDMJob,
  orderWithComment,
  isYellow,
  trackingNumber,
  trackingCarrier,
  statusOptions,
  vendorsList,
  validMessageState,
  beginningDebounceForEachChange,
  beginningDebounceForShipmentChanges,
}: OrderItemInListProps) => {
  /**
   *  Vendor Job, Vendor, Mail PO, Tracking number. These fields
   *  Can has the following values (user written value, NULL or isEditable)
   *
   *  If value is NULL field(input, select, etc) hidden.
   *  If value is equal 'isEditable' or user written value field must be visible.
  */

  const { openJobsSelectedRows } = useAppSelector(selectSets);
  const dispatch = useAppDispatch();

  const artwork = requestToArtwork(frontArtWork || backArtWork);

  const updateSingleStatus = async (e: SelectChangeEvent<unknown>) => {
    try {
      await dispatch(updateSetsStatuses({
        status: e.target.value as OrderStatusesEnum,
        ids: [setId],
      }));

      if (
        [OrderStatusesEnum.Canceled, OrderStatusesEnum.Complete]
        .includes(e.target.value as OrderStatusesEnum)
      ) {
        await dispatch(getEstimatesCount());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledOrderTableRow
      className={getPriority({
        hasOpenTasks,
        isEDDMJob,
        orderWithComment,
        isYellow,
      })}
    >
      <StyledTCell text="" width="20px">
        <Checkbox
          onClick={() => {
            dispatch(selectRow(setId));
          }}
          checked={openJobsSelectedRows.includes(setId)}
        />
      </StyledTCell>
      <StyledTCell>
        <OrderIdLink
          onClick={() => PrevState.set({ scroll: window?.scrollY })}
          to={`/orders/${orderId}/${setId}`}
        >
          #
          {setNumber}
        </OrderIdLink>
      </StyledTCell>
      <StyledTCell>
        {artwork ? (
            <img
              src={artwork}
              alt=""
              width="68px"
              height="68px"
              style={{ objectFit: 'cover' }}
            />
          ) : null}
      </StyledTCell>
      <StyledTCell
        text={
          date
            ? moment(date).format(SHORT_DATE_FORMAT)
            : moment().format(SHORT_DATE_FORMAT)
      }
      />
      <StyledTCell text={`${billTo}`} />
      <StyledTCell>
        <StyledSelect
          id=""
          label=""
          labelId=""
          defaultValue={status}
          onChange={updateSingleStatus}
        >
          {statusOptions.map((item) => <MenuItem key={item.value} value={item.value}>{item.optionName}</MenuItem>)}
        </StyledSelect>
      </StyledTCell>
      <StyledTCell>
        {
          vendorJob !== null && (
            <>
              <Input
                defaultValue={findIsEditable(vendorJob)}
                name="vendorJob"
                placeholder="Enter..."
                onChange={(e) => {
                  beginningDebounceForEachChange(
                    OrderListUpdateRequestFieldKeys.vendorJob,
                    e.target.value,
                  );
                }}
              />
              {
                validMessageState?.where === OrderListUpdateRequestFieldKeys.vendorJob &&
                validMessageState?.rowId === setId &&
                <ErrorMessage message={validMessageState.message} />
              }
            </>
          )
        }

      </StyledTCell>
      <StyledTCell>
        {
          vendorId !== null && (
            <StyledSelect
              value={findIsEditable(vendorId, "Select")}
              defaultValue="Select"
              sx={{ width: '150px', height: '45px' }}
              onChange={(el) => {
                beginningDebounceForEachChange(
                  OrderListUpdateRequestFieldKeys.vendorId,
                  el.target.value as string,
                );
              }}
            >
              <MenuItem value="Select">
                <Typography variant="body3">Select</Typography>
              </MenuItem>
              {vendorsList.map(({ optionName, value }) => (
                <MenuItem key={value} value={value} selected={value === vendorId}>
                  <Typography variant="body3">{optionName}</Typography>
                </MenuItem>
              ))}
            </StyledSelect>
          )
        }

      </StyledTCell>
      <StyledTCell width="100px">
        {cost && priceFormatting(cost)}
      </StyledTCell>
      <StyledTCell>
        {
          shipmentId && trackingNumber !== null && (
            <OrderShipments
              shipment={{
                id: shipmentId,
                trackingNumber: findIsEditable(trackingNumber),
                trackingCarrier,
              }}
              onChange={(shipmentsUpdatePayload: UpdateShipmentPayload) => {
                beginningDebounceForShipmentChanges(shipmentsUpdatePayload);
              }}
            />
          )
        }
      </StyledTCell>
      <StyledTCell>
        {mailPo && (
          <Input
            name="test"
            defaultValue={findIsEditable(mailPo)}
            placeholder="Enter..."
            onChange={(e) => {
              beginningDebounceForEachChange(
                OrderListUpdateRequestFieldKeys.mailingPO,
                e.target.value,
              );
            }}
            sx={{
              minWidth: '80px',
            }}
          />
        )}
      </StyledTCell>
    </StyledOrderTableRow>
  );
};

export default SetItemInList;
