import { memo, useState } from "react";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import {
  StyledMuiTable,
  StyledTableContainer,
  StyledTableRow,
  TableCellStyle,
} from "./styled";
import { IStyledTable } from "./types";

const StyledTable = ({
  headCells,
  children,
  seperatedCategoriesData,
  tableTitle,
  handleCheck,
  colSpan,
  btn = null,
  checkboxVia,
}: IStyledTable) => {
  return (
    <StyledTableContainer>
      <StyledMuiTable>
        <TableHead>
          <StyledTableRow>
            {tableTitle ? (
              <TableCell colSpan={colSpan}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body3" textTransform="uppercase">
                    {tableTitle}
                  </Typography>
                  {btn && btn}
                </Stack>
              </TableCell>
            ) : headCells ? (
              headCells.map((props) => {
                const { paramKey, ...restProps } = props;

                const { label, id, checkbox } = restProps;

                return (
                  <TableCell key={label} {...restProps}>
                    <Typography textTransform="uppercase" variant="body3">
                      <TableCellStyle>
                        <span>{checkbox ? checkboxVia : label}</span>
                        {label.toUpperCase() !== "QUANTITY" && id && handleCheck && (
                          <Checkbox
                            disableRipple
                            checked={seperatedCategoriesData?.includes(id)}
                            onChange={() => handleCheck(id)}
                          />
                        )}
                      </TableCellStyle>
                    </Typography>
                  </TableCell>
                );
              })
            ) : null}
          </StyledTableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </StyledMuiTable>
    </StyledTableContainer>
  );
};

export default memo(StyledTable);
