import { memo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import Checkbox from '@containers/common/Checkbox';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { addUser, editUser } from '@features/users/actions';
import PAGE_ROUTES from '@routes/routingEnum';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import { selectUsers } from '@features/users/selectors';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { resetUsers } from '@features/users/slice';
import RowComponent from '@containers/common/Table/components/RowComponent';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import {
  AddUserSchema, IAddDataForm, checkboxRows, inputsRows, defaultValues,
  formattingPayload, formattingDefaultValue, EditUserSchema,
} from './helpers';
import { IInputsTable } from './types';

const InputsTable = ({ userInfo }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectUsers);
  const ValidationSchema = userInfo ? EditUserSchema : AddUserSchema;
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(ValidationSchema as any),
    defaultValues: userInfo ? formattingDefaultValue(userInfo) : defaultValues,
  });

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const { handleSubmit, setError } = methods;

  const onSubmit = (data: IAddDataForm) => {
    const payload = formattingPayload(data);

    dispatch(userInfo ? editUser(payload) : addUser(payload))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!userInfo) {
          dispatch(resetUsers());
          navigate(PAGE_ROUTES.USERS);
        }
      })
      .catch((e) => {
        setIsSuccessMsg({ msg: '', status: null });

        if (e.message === 'User does not exist!') {
          navigate(PAGE_ROUTES.USERS);
        } else {
          setError('email', { message: e.message });
        }
      });
  };

  return (
    <TitlesWithBackButton title={userInfo ? 'Edit User' : 'Add User'} path="USERS">
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="USER INFO" colSpan={2}>
            {inputsRows.map((item) => {
              const { field } = item;

              return (
                <RowComponent
                  key={item.label}
                  {...item}
                  isRequired={userInfo && field !== 'password'}
                >
                  <ReusableFields {...item} />
                </RowComponent>
              );
            })}
            {checkboxRows.map((item) => (
              <RowComponent key={item.label} {...item}>
                <Checkbox name={`permissions[${item.field}]`} />
              </RowComponent>
            ))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
