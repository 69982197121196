import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { IBillingInfo } from '@features/customers/billingInfo/types';
import { editBillingInfo } from '@features/customers/billingInfo/actions';
import { selectBillingInfo } from '@features/customers/billingInfo/selectors';
import { buildRoute } from '@routes/helpers';
import PAGE_ROUTES from '@routes/routingEnum';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { AddDataSchema, IAddDataForm, gettingStateOptions } from './helpers';
import { inputsRows } from './tableData';
import { countryOptions } from './optionsList';

interface IInputsTable{
  editData?: IBillingInfo;
}

const InputsTable = ({ editData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customerId = '' } = useParams();
  const { actionLoading } = useAppSelector(selectBillingInfo);
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: editData,
  });

  const { handleSubmit, watch, setValue } = methods;
  const { country, state } = watch();
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const onSubmit = (data: IAddDataForm) => {
    const path = buildRoute(PAGE_ROUTES.EDIT_CUSTOMER, { id: customerId });

    dispatch(editBillingInfo(data))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!editData) {
          navigate(path);
        }
      }).catch(() => {
        navigate(path);
      });
  };

  const stateOptions = gettingStateOptions(country);

  useEffect(() => {
    const isIncluded = stateOptions.find((item) => item.value === state);

    if (!isIncluded || !country) {
      setValue('state', '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <TitlesWithBackButton
      title="Edit Billing Info"
      path="EDIT_CUSTOMER"
      params={{ id: customerId }}
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="BILLING INFO" colSpan={2}>
            {inputsRows.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[{
                    field: 'country',
                    options: countryOptions,
                  }, {
                    field: 'state',
                    options: stateOptions,
                  }]}
                />
              </RowComponent>
            ))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
