import { memo } from 'react';

import Select from '@containers/common/Select';
import { useFormContext } from 'react-hook-form';
import { Job } from '@features/orders/order/types';
import Textarea from '@containers/common/Textarea';
import { Box } from '@mui/material';
import Input from '@containers/common/Input';
import { UnderlinedLink } from '@containers/common/Underlined/styles';
import { getCDNImagePath } from '@utils/helpers';
import FileUploader from '@containers/common/FileUploader';

import { IVendorOptions } from '../../../utils/types';
import {
  JobStatusAndVendorWrapper,
  Container,
  Proof,
  ProofTitle,
  JobStatusWrapper,
  ProofContent,
  VendorLineWrapper,
  StyledText,
  NotWrapper,
  SpecificCase,
  MailingSection,
} from './styled';

interface JobRightSectionProps {
  job: Job;
  keyByIndex: string;
  vendorsList: IVendorOptions[];
}

const JobRightSection = ({
  job,
  keyByIndex,
  vendorsList,
}: JobRightSectionProps) => {
  const { proofLogs, isMailing } = job;
  const { watch, register } = useFormContext();

  const yesNoOptions = [
    {
      optionName: 'Yes',
      value: true,
    },
    {
      optionName: 'No',
      value: false,
    },
  ];

  const mailingFile = watch(`${keyByIndex}.mailingFile`);
  const postageFromVendor = watch(`${keyByIndex}.postageFromVendor`);
  const customerPostage = watch(`${keyByIndex}.customerPostage`);
  const postageToVendor = watch(`${keyByIndex}.postageToVendor`);
  const vendor1Value = watch(`${keyByIndex}.vendorJob`) === 'isEditable' ? '' : watch(`${keyByIndex}.vendorJob`);

  return (
    <Container>
      <JobStatusAndVendorWrapper>
        <JobStatusWrapper width={isMailing ? "140px" : "100px"}>

          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>
                Vendor 1:
              </StyledText>
            </Box>
            <Box>
              <Select
                name={`${keyByIndex}.vendorId`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendorId`)}
              />
            </Box>
            <SpecificCase>
              <StyledText>
                Vendor PO:
              </StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={vendor1Value}
                {...register(`${keyByIndex}.vendorJob`)}
              />
            </SpecificCase>
          </VendorLineWrapper>
          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>
                Vendor 2:
              </StyledText>
            </Box>
            <Box>
              <Select
                name={`${keyByIndex}.vendor2Id`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendor2Id`)}
              />
            </Box>
            <SpecificCase>
              <StyledText>
                Vendor PO:
              </StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorJob2`)}
                {...register(`${keyByIndex}.vendorJob2`)}
              />
            </SpecificCase>
          </VendorLineWrapper>
          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>
                Vendor 3:
              </StyledText>
            </Box>
            <Box>
              <Select
                name={`${keyByIndex}.vendor3Id`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendor3Id`)}
              />
            </Box>
            <SpecificCase>
              <StyledText>
                Vendor PO:
              </StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorJob3`)}
                {...register(`${keyByIndex}.vendorJob3`)}
              />
            </SpecificCase>
          </VendorLineWrapper>

          { isMailing && (
            <MailingSection>
              <Box>
                <StyledText>Received postage amount from vendor:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: postageFromVendor ? 'Yes' : 'No',
                    value: postageFromVendor,
                  }}
                  name={`${keyByIndex}.postageFromVendor`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Customer paid postage:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: customerPostage ? 'Yes' : 'No',
                    value: customerPostage,
                  }}
                  name={`${keyByIndex}.customerPostage`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Postage paid to vendor:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: postageToVendor ? 'Yes' : 'No',
                    value: postageToVendor,
                  }}
                  name={`${keyByIndex}.postageToVendor`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Mail PO:</StyledText>
                <Input
                  orderDesignStyle
                  {...register(`${keyByIndex}.mailingPO`)}
                  placeholder="Enter"
                />
              </Box>
              <Box>
                <StyledText>Mailing List:</StyledText>
                <Box
                  sx={{
                    display: 'flex',
                    gap: "20px",
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    button: {
                      width: '100px',
                    },
                  }}
                >
                  {mailingFile && (
                    <UnderlinedLink to={getCDNImagePath(mailingFile)} width="120px" target="_blank">
                      {mailingFile}
                    </UnderlinedLink>
                  )}
                  <FileUploader
                    outOfFormSystem="mailingFile"
                    name={`${keyByIndex}.mailingFile`}
                    errorMessage=""
                    fileExtensions={['.csv', '.xls', '.xlsx']}
                    btnProps={{
                      maxWidth: '80px',
                      btnText: "Choose File",
                      isOutlined: true,
                    }}
                  />
                </Box>
              </Box>
            </MailingSection>
          )}

          <NotWrapper width={isMailing ? "140px" : "100px"}>
            <StyledText>
              Internal Job Notes:
            </StyledText>
            <Textarea vtResize {...register(`${keyByIndex}.notes`)} placeholder="Enter" />
          </NotWrapper>
        </JobStatusWrapper>
      </JobStatusAndVendorWrapper>
      <Proof>
        <ProofTitle>Proof upload & Status Log:</ProofTitle>
        <ProofContent>
          {proofLogs.map((item) => <StyledText key={item.id}>{item.text}</StyledText>)}
        </ProofContent>
      </Proof>
    </Container>
  );
};

export default memo(JobRightSection);
