import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useParams } from 'react-router-dom';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import { editProductsQuantities } from '@features/products/productsQuantity/actions';
import { selectProductsQuantities } from '@features/products/productsQuantity/selectors';
import Typography from '@mui/material/Typography';
import StyledTable from '@containers/common/Table';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { AddDataSchema, IAddDataForm, formattingPayload } from './helpers';
import QuantityTable from './QuantityTable';
import { headCells } from './QuantityTable/tableData';

const EditComponent = () => {
  const dispatch = useAppDispatch();
  const { actionLoading, data: productQuantities } = useAppSelector(selectProductsQuantities);
  const { id } = useParams();
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any),
    defaultValues: { quantities: productQuantities },
  });

  const { handleSubmit, watch, setValue, setError } = methods;

  useEffect(() => {
    setValue('quantities', productQuantities);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productQuantities]);

  const onSubmit = async (data: IAddDataForm) => {
    if (!id) {
      return;
    }

    try {
      const body = formattingPayload(data);

      await dispatch(editProductsQuantities({ body, id })).unwrap();
      setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
    } catch (e: any) {
      if (typeof e?.message === 'string') {
        setError('quantities', { message: e?.message });
      } else {
        setError('quantities', { message: GLOBAL_MESSAGES.wrong });
      }
    }
  };

  const { quantities } = watch();

  return (
    !!quantities.length && (
      <>
        <Typography variant="h9" mb="16px">Edit</Typography>
        <FormProvider {...methods}>
          <StyledStack
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            mb="32px"
          >
            <StyledTable headCells={headCells}>
              {
              quantities.map((_: any, idx: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <QuantityTable key={idx} idx={idx} />
              ))
            }
            </StyledTable>
            <SubmitBtn actionLoading={actionLoading} />
            <GlobalUpdatesMessage {...isSuccessMsg} />

          </StyledStack>
        </FormProvider>
      </>

    )
  );
};

export default memo(EditComponent);
