import { styled } from '@mui/material/styles';
import { Box, Checkbox } from '@mui/material';

export const Container = styled(Box)(() => ({
}));

export const StyledLabel = styled('label')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: "center",
  margin: '15px 0',
}));

export const CheckboxGroup = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  label: {
    margin: '0px !important',
  },
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
}));
